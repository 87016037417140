import { ReactElement, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';

export function ChangeLogInfoBox({ branch }: { branch?: string }): ReactElement {
    const xBranch = /\d+\.\d+\.x$/;
    const zeroBranch = /\d+\.\d+\.0$/;
    const patchBranch = /\d+\.\d+\.\d+$/;
    const rolloutBranch = /\d+\.\d+\.(\d+|x)(-.+)$/;

    const [infoText, setInfoText] = useState<string>('Loading ...');

    useEffect(() => {
        if (!branch) {
            setInfoText('No matching branch');
            return;
        }

        const [major, minor, patch] = branch?.split('.');

        if (xBranch.test(branch)) {
            setInfoText(
                `Shows changes to trunk from ${major}.${Number(minor) - 1}.x to ${branch}'s initial commit. Shows anything added to ${branch}`,
            );
        } else if (zeroBranch.test(branch)) {
            setInfoText(
                `Shows changes to trunk from ${major}.${Number(minor) - 1}.x to ${branch}'s initial commit. Shows commits to ${major}.${minor}.x. Shows commits to ${major}.${minor}.0`,
            );
        } else if (patchBranch.test(branch)) {
            setInfoText(
                `Shows changes to ${major}.${minor}.x between the creation of ${major}.${minor}.${Number(patch) - 1} and ${branch}. Shows commits to ${branch}`,
            );
        } else if (rolloutBranch.test(branch)) {
            setInfoText(`Show changes to ${branch}. Shows commits to patch branch.`);
        } else {
            setInfoText('The current branch is invalid.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branch]);

    return (
        <>
            <InfoIcon color='secondary' sx={{ mr: 2 }} />
            <Typography color='gray'>{infoText}</Typography>
        </>
    );
}
