import { Fragment, ReactElement, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Edit as EditIcon, Share as ShareIcon } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { SquareButton, AvatarChip } from '@tactileentertainment/core-designsystem';
import { useLogin } from '@tactileentertainment/core-shared.auth-react';
import moment from 'moment';
import { AppThunkDispatch } from '../../store';
import { CancelBuildButton, ReorderBuildButton } from '../builds/BuildActions';
import { IBuildsSliceState } from '../builds/interfaces';
import CustomLink from '../commons/CustomLink';
import { EBuildStatus } from '../commons/enums';
import { getUpsourceLink } from '../commons/getUpsourceLink';
import { IAuthor, IBuild, IBuildAgent } from '../commons/interfaces';
import LinkToBuildAgent from '../commons/LinkToBuildAgent';
import LinkToParentBuild from '../commons/LinkToParentBuild';
import { IConfiguration } from '../newBuild/interfaces';
import { getBuildConfigurations } from '../newBuild/newBuildSlice';
import BuildActions from './BuildActions';
import {
    getBuildActions,
    getBuildResultById,
    preventAndStopLinkClick,
    selectBuildDetailsBuild,
    selectBuildDetailsResult,
    selectBuildStatus,
    setAppUploaderDialogOpen,
    setBuildDialog,
} from './buildDetailsSlice';
import DownloadBuildFromDetailsRowButton from './DownloadBuildFromDetailsRowButton';
import { IInfoColumn } from './interfaces';
import OrderTestSuiteButtonDialog from './OrderTestSuiteButtonDialog';

const BoxColumn = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    flexBasis: '6%',
    [theme.breakpoints.down('sm')]: {
        flexBasis: '50%',
    },
}));

export default function BuildInfo(): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const build = useSelector(selectBuildDetailsBuild);
    const theme = useTheme();
    const [buildConfiguration, setBuildConfiguration] = useState<IConfiguration | undefined>();
    const isMobile = useMediaQuery('(max-width: 760px)');

    useEffect(() => {
        if (build) {
            dispatch(
                getBuildConfigurations({
                    projectName: build.projectName,
                    branch: build.branch,
                    revision: build.revision,
                }),
            ).then(({ payload }) => {
                const buildConfig =
                    payload?.buildConfigs?.[build.buildConfigFileName || 'build_config.json'] || undefined;
                setBuildConfiguration(buildConfig);
            });
            dispatch(getBuildActions());
        }
    }, [build, dispatch]);

    if (!build) {
        return null;
    }

    const infoColumns: IInfoColumn[] = [
        { caption: 'Platform', body: build.platform },
        { caption: 'Type', body: build.type },
        { caption: 'Revision', body: build.revision },
        { caption: 'Version', body: build.versionName },
        { caption: 'Duration', started: build.started, completed: build.completed },
        { caption: 'Started', started: build.started },
    ];

    return (
        <Box p={1}>
            <Grid
                container
                alignItems='center'
                data-testid='build-details-info'
                sx={{
                    width: '100%',
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.common.white,
                    px: 2,
                }}
            >
                {infoColumns.slice(0, 4).map((column, index) => {
                    if (column.caption === 'Revision') {
                        return (
                            <Fragment key={index}>
                                <RevisionColumn
                                    build={build}
                                    buildConfiguration={buildConfiguration}
                                    data-testid={`build-details-info-column-revision`}
                                />
                            </Fragment>
                        );
                    } else {
                        return (
                            <InfoColumn
                                key={index}
                                column={column}
                                data-testid={`build-details-info-column-${column.caption.toLowerCase()}`}
                            />
                        );
                    }
                })}

                <BuildAgentColumn agent={build.buildAgent} />

                {!isMobile && (
                    <Divider
                        orientation='vertical'
                        flexItem
                        sx={{ mr: 3, ml: { sm: 2, lg: -1 }, borderRightWidth: 2 }}
                    />
                )}

                {infoColumns.slice(4).map((column, index) => {
                    return (
                        <InfoColumn
                            key={index}
                            column={column}
                            data-testid={`build-details-info-column-${column.caption.toLowerCase()}`}
                        />
                    );
                })}

                <AuthorColumn author={build.author} />

                <ParentBuildInfo parentBuild={build.parentBuild} />
                <JiraLinks jiraTasks={build.jiraTasks} />

                <Typography
                    component='span'
                    sx={{ flexGrow: 1, [theme.breakpoints.down('sm')]: { display: 'none' } }}
                ></Typography>

                <BuildActions build={build} />
                <OrderTestSuiteButtonDialog build={build} />
                <BuildInfoActionButtons build={build} />
                <DownloadBuildFromDetailsRowButton />
            </Grid>
        </Box>
    );
}

function InfoColumn({ column }: { column: IInfoColumn }): ReactElement | null {
    const { caption, body, started, completed } = column;

    if (!body && !started) {
        return null;
    }

    return (
        <>
            <BoxColumn
                sx={{ ...(caption === 'Started' && { minWidth: '110px' }) }}
                data-testid={`build-details-info-${caption.toLowerCase()}`}
            >
                <Typography variant='caption' fontWeight={500} color='textSecondary'>
                    {caption}
                </Typography>
                <br />
                <Typography variant='body1' fontWeight={700}>
                    {body}
                </Typography>
                <ElapsedTime caption={caption} started={started} completed={completed} />
            </BoxColumn>
        </>
    );
}

function ElapsedTime({
    caption,
    started,
    completed,
}: {
    caption: string;
    started?: string;
    completed?: string;
}): ReactElement | null {
    if (!started) {
        return null;
    }

    if (caption === 'Started') {
        return (
            <Typography
                component='span'
                variant='body1'
                color='textPrimary'
                sx={{ minWidth: '110px' }}
                fontWeight={700}
                title={moment(started).format('DD-MM-YYYY HH:mm')}
            >
                <Moment format='DD/MM HH:mm'>{started}</Moment>
            </Typography>
        );
    }

    return (
        <Typography
            component='span'
            variant='body1'
            color='textPrimary'
            fontWeight={700}
            title={`${moment(started).format('DD-MM-YYYY HH:mm')}${completed ? '  -  ' + moment(completed).format('DD-MM-YYYY HH:mm') : ''}`}
        >
            {completed ? (
                <Moment duration={started} date={completed} />
            ) : (
                <Moment date={started} durationFromNow interval={1000} />
            )}
        </Typography>
    );
}

function AuthorColumn({ author }: { author?: IAuthor }): ReactElement | null {
    if (!author) {
        return null;
    }

    return (
        <BoxColumn data-testid='build-details-info-orderedBy'>
            <Typography variant='caption' fontWeight={500} color='textSecondary'>
                Ordered by
            </Typography>
            <br />
            <AvatarChip label={author.email} altLabel={author.name} src={author.imageUrl} sx={{ mr: 3 }} />
        </BoxColumn>
    );
}

function RevisionColumn({
    build,
    buildConfiguration,
}: {
    build: IBuild;
    buildConfiguration?: IConfiguration;
}): ReactElement | null {
    const [upsourceProjectName, setUpsourceProjectName] = useState('');

    useEffect(() => {
        if (buildConfiguration) {
            setUpsourceProjectName(buildConfiguration.project?.upsource_project_id || '');
        }
    }, [buildConfiguration]);

    return (
        <BoxColumn sx={{ flexBasis: '7%', minWidth: '100px' }} data-testid='build-details-info-revision'>
            <Typography variant='caption' color='textSecondary'>
                Revision
            </Typography>
            <br />
            {upsourceProjectName ? (
                <Typography variant='body1'>
                    <CustomLink
                        to={{ pathname: getUpsourceLink(upsourceProjectName, build.branch, build.revision) }}
                        onClick={preventAndStopLinkClick}
                        target='_blank'
                        sx={{ fontWeight: 700, textTransform: 'capitalize', m: 0.25 }}
                        color='primary'
                    >
                        {build.revision}
                    </CustomLink>
                </Typography>
            ) : (
                <Typography variant='body1' fontWeight={700}>
                    {build.revision}
                </Typography>
            )}
        </BoxColumn>
    );
}

function BuildAgentColumn({ agent }: { agent?: IBuildAgent }): ReactElement | null {
    return (
        <BoxColumn sx={{ flexBasis: '7%', minWidth: '100px' }} data-testid='build-details-info-agent'>
            <Typography variant='caption' color='textSecondary'>
                Build Agent
            </Typography>
            <br />
            <Typography variant='body1' color='primary'>
                {agent ? <LinkToBuildAgent buildAgent={agent} /> : '--'}
            </Typography>
        </BoxColumn>
    );
}

function ParentBuildInfo({ parentBuild }: { parentBuild: string | undefined }): ReactElement | null {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const [projectName, setProjectName] = useState('');
    const [versionName, setVersionName] = useState('');
    const [buildNumber, setBuildNumber] = useState(0);

    useEffect(() => {
        if (parentBuild) {
            dispatch(getBuildResultById(parentBuild)).then(({ payload }: any) => {
                setProjectName(payload.build.projectName);
                setVersionName(payload.buildResult?.versionName);
                setBuildNumber(payload.build.buildNumber);
            });
        }
    }, [dispatch, parentBuild, projectName, versionName, buildNumber]);

    const component = parentBuild ? (
        <>
            <Divider orientation='vertical' flexItem />
            <BoxColumn sx={{ flexBasis: '10%', minWidth: 'auto' }}>
                <Typography variant='caption' color='textSecondary'>
                    Parent Build
                </Typography>
                <br />
                <LinkToParentBuild projectName={projectName} versionName={versionName} buildNumber={buildNumber} />
            </BoxColumn>
        </>
    ) : null;
    return component;
}

function JiraLinks({ jiraTasks = [] }: { jiraTasks?: string[] }): ReactElement | null {
    const dispatch = useDispatch();
    const { isLoggedIn, handleLogin } = useLogin();
    const [editVisible, setEditVisible] = useState(false);

    const handleEditClick = () => {
        if (isLoggedIn) {
            dispatch(setBuildDialog({ open: true, jiraTasks }));
        } else {
            handleLogin();
        }
    };

    return (
        <BoxColumn
            sx={{
                cursor: 'pointer',
                ...(!jiraTasks || !jiraTasks.length ? { minWidth: '100px' } : { minWidth: '120px', flexGrow: 1 }),
            }}
            onMouseEnter={() => setEditVisible(true)}
            onMouseLeave={() => setEditVisible(false)}
            onClick={handleEditClick}
            data-testid='build-details-info-jira'
        >
            <Typography variant='caption' color='textSecondary'>
                JIRA Tasks
                {editVisible && <EditIcon style={{ margin: 0, marginLeft: '5px', padding: 0, fontSize: '15px' }} />}
            </Typography>
            <br />
            {!jiraTasks || !jiraTasks.length ? (
                <Typography variant='body1' color='primary' fontWeight={700} noWrap>
                    ADD
                </Typography>
            ) : (
                <Typography variant='body1' noWrap>
                    {jiraTasks.map((task, index) => {
                        return (
                            <Fragment key={index}>
                                <CustomLink
                                    to={{ pathname: `https://tactileentertainment.atlassian.net/browse/${task}` }}
                                    onClick={preventAndStopLinkClick}
                                    target='_blank'
                                    color='primary'
                                    sx={{ display: 'inline', fontWeight: 700, textTransform: 'capitalize', m: 0.25 }}
                                >
                                    {task}
                                </CustomLink>
                                {index !== jiraTasks.length - 1 && ', '}
                            </Fragment>
                        );
                    })}
                </Typography>
            )}
        </BoxColumn>
    );
}

function BuildInfoActionButtons({ build }: { build: IBuild }): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const status = useSelector(selectBuildStatus);
    const buildResult = useSelector(selectBuildDetailsResult);
    const { isLoggedIn, handleLogin } = useLogin();

    const showAppUploaderButton =
        status === EBuildStatus.finished && buildResult?.releaseCandidate && build.kind !== 'patch';

    const handleOpenShareDialog = () => {
        dispatch(setBuildDialog({ open: true, share: true, artifact: buildResult?.mainArtifact }));
    };

    const handleOpenAppUpload = () => {
        if (isLoggedIn) {
            dispatch(setAppUploaderDialogOpen(true));
        } else {
            handleLogin();
        }
    };

    const showReorderButton =
        status === EBuildStatus.cancelled || status === EBuildStatus.failed || status === EBuildStatus.finished;
    const showCancelButton = status === EBuildStatus.queued || status === EBuildStatus.running;

    return (
        <Box
            sx={{
                color: (theme) => theme.palette.text.secondary,
                '& > *': {
                    margin: (theme) => theme.spacing(1, 0.5),
                    p: 0,
                    my: 1,
                },
            }}
            data-testid='build-details-info-buttons'
        >
            {showReorderButton && <ReorderBuildButton build={build} />}
            {showCancelButton && <CancelBuildButton build={build} variant='outlined' />}
            {buildResult?.mainArtifact && status === EBuildStatus.finished && (
                <SquareButton
                    onClick={handleOpenShareDialog}
                    title='Share'
                    color='secondary'
                    variant='outlined'
                    sx={{ mr: 1 }}
                >
                    <ShareIcon color='secondary' />
                </SquareButton>
            )}
            {showAppUploaderButton && (
                <Button
                    variant='outlined'
                    title='Upload to app store'
                    aria-label='uploadToAppStore'
                    onClick={handleOpenAppUpload}
                    color='secondary'
                    sx={{ mr: 1, py: 1.25, my: { xs: 2, sm: 0 } }}
                >
                    Upload
                </Button>
            )}
        </Box>
    );
}
